<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex>
        <PageLoader v-bind:storage="appLoading" v-bind:contain="true" />
        <apexchart
          width="100%"
          height="300px"
          type="line"
          :options="chartOptions"
          :series="chartData"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "hives"],
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      xaxis: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    chartData() {
      return this.storage;
    },
    chartOptions: function () {
      var that = this;
      return {
        chart: {
          id: "Apiaries",
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          sparkline: {
            enabled: false,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          tooltip: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
            colorStops: [
              {
                offset: 0,
                color: "#069EFC",
                opacity: 1,
              },
              {
                offset: 100,
                color: "#14F4C9",
                opacity: 1,
              },
            ],
          },
        },
        tooltip: {
          custom: function ({ series, dataPointIndex }) {
            var content =
              `<div data-v-5adcc081="" class="flex xs12" style="background-color:transparent">
                <div data-v-5adcc081="" class="rounded-t-lg v-card v-card--flat v-sheet  rounded-0" style="background-color: rgb(17, 39, 84);width:150px">
                  <div data-v-5adcc081="" class="layout wrap justify-start py-1 px-2">
                    <div data-v-5adcc081="" class="flex xs8 xl8 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);">Apiaries</span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs4 xl4 text-left">
                      <span data-v-5adcc081="" style="font-family: opensanslight; font-size: 14px; color: rgb(201, 201, 201);">` +
              that.xaxis[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
                <div data-v-5adcc081="" class="rounded-b-lg v-card v-card--flat v-sheet theme--light rounded-0" style="background-color: rgb(4, 28, 75); ">
                  <div data-v-5adcc081="" class="layout wrap justify-center px-2">
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 19px; color: rgb(255, 255, 255);">` +
              series[0][dataPointIndex] +
              `</span>
                    </div>
                    <div data-v-5adcc081="" class="flex xs12 text-left">
                      <span data-v-5adcc081="" style="font-family: opensansbold; font-size: 12px; color: rgb(61, 193, 49);">Hives: ` +
              that.hives.data[dataPointIndex] +
              `</span>
                    </div>
                  </div>
                </div>
              </div>`;
            return content;
          },
        },
      };
    },
  },
};
</script>