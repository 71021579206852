<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Bee Keepers </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs3 text-left>
        <span class="itemValue">Username</span>
      </v-flex>
      <v-flex xs3 text-left>
        <span class="itemValue">Phone</span>
      </v-flex>
      <v-flex xs6 xl6 text-left>
        <span class="itemValue">Email</span>
      </v-flex>
    </v-layout>
    <template v-for="(item, i) in storage">
      <router-link :to="'/Admin/Users/' + item._id" :key="i">
        <v-layout wrap justify-start>
          <v-flex xs3 xl3 text-left>
            <span class="itemKey">{{ item.username }}</span>
          </v-flex>
          <v-flex xs3 text-left>
            <span class="itemKey"
              >{{ item.phone }}
              <span v-if="!item.phone">-</span>
            </span>
          </v-flex>
          <v-flex xs6 xl6 text-left>
            <span class="itemKey">
              {{ item.email }}
              <span v-if="!item.email">-</span>
            </span>
          </v-flex>
        </v-layout>
      </router-link>
    </template>

    <v-layout wrap justify-center>
      <v-flex xs12 pr-4 text-right>
        <router-link to="/Admin/Users">
          <span
            style="font-family: poppinsregular; font-size: 13px; color: #0073cf"
          >
            View More
          </span>
          <span class="itemArrow" style="color: #0073cf">>></span>
        </router-link>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
};
</script>