<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-end>
          <v-flex lg3 xl2>
            <v-select
              outlined
              dense
              color="#000000"
              label="Map Type"
              v-model="mapType"
              :items="mapTypes"
              item-color="#000000"
              hide-details
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item }}
                </span>
              </template>
            </v-select>
          </v-flex>

          <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 px-md-1>
            <v-menu
              ref="fromDatePicker"
              v-model="fromDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="fromDate"
                  label="From Date"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="fromDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm4 md2 xl2 text-left pt-2 pt-sm-0 pl-md-1>
            <v-menu
              ref="toDatePicker"
              v-model="toDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  dense
                  clearable
                  v-model="toDate"
                  label="To Date"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="toDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-2>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-3>
              <div id="map"></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCaccNxbzwR9tMvkppT7bT7zNKjChc_yAw&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
var marker;
export default {
  props: ["userId"],
  components: {},
  data() {
    return {
      appLoading: false,
      map: "",
      mapData: [],
      mapTypes: ["All", "Bee Keepers", "Apiaries", "Hives"],
      mapType: "All",
      fromDate: null,
      toDate: null,
      fromDatePicker: false,
      toDatePicker: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    fromDate() {
      this.getData();
    },
    toDate() {
      this.getData();
    },
    mapType() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/getmap",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            var mapItems = [];
            mapItems.push(response.data.data.users);
            mapItems.push(response.data.data.apiaries);
            mapItems.push(response.data.data.hives);
            this.mapData = mapItems;
            var vm = this;
            var mapCanvas = document.getElementById("map");
            if (mapItems[0].data.length > 0) {
              var mapOptions = {
                center: new google.maps.LatLng(
                  mapItems[0].data[0].location[1],
                  mapItems[0].data[0].location[0]
                ),
                zoom: 3,

                mapTypeId: google.maps.MapTypeId.TERRAIN,
              };

              vm.map = new google.maps.Map(mapCanvas, mapOptions);
              if (this.mapType == "All") {
                for (var item = 0; item < mapItems.length; item++) {
                  if (item == 0) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/usericon.png", // url
                        scaledSize: new google.maps.Size(35, 40), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Username : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[0].data[i].username +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">User Code : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[0].data[i].usercode +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Admin/Users/` +
                        mapItems[0].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }

                  // Apiaries
                  if (item == 1) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/redmarker.png", // url
                        scaledSize: new google.maps.Size(20, 32), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Apiary ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].apiaryid +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hives : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].colonycount +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Apiary/` +
                        mapItems[item].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }

                  // Hives
                  if (item == 2) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/yellowmarker.png", // url
                        scaledSize: new google.maps.Size(20, 32), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Tag ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].tagid +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hive Status : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].colonystatus +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Hive/` +
                        mapItems[item].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }
                }
              }
              // Map of Users
              if (this.mapType == "Bee Keepers") {
                for (var item = 0; item < mapItems.length; item++) {
                  if (item == 0) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/usericon.png", // url
                        scaledSize: new google.maps.Size(35, 40), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Username : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[0].data[i].username +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">User Code : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[0].data[i].usercode +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Admin/Users/` +
                        mapItems[0].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }
                }
              }

              // Map of Apiaries
              if (this.mapType == "Apiaries") {
                for (var item = 0; item < mapItems.length; item++) {
                  if (item == 1) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/redmarker.png", // url
                        scaledSize: new google.maps.Size(20, 32), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Apiary ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].apiaryid +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hives : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].colonycount +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Apiary/` +
                        mapItems[item].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }
                }
              }

              // Map of Hives
              if (this.mapType == "Hives") {
                for (var item = 0; item < mapItems.length; item++) {
                  // Hives
                  if (item == 2) {
                    for (var i = 0; i < mapItems[item].data.length; i++) {
                      var lat = mapItems[item].data[i].location[1];
                      var lon = mapItems[item].data[i].location[0];
                      var latlngset = new google.maps.LatLng(lat, lon);
                      var icon = {
                        url:
                          "http://arhiveadmin.leopardtechlabs.com/img/yellowmarker.png", // url
                        scaledSize: new google.maps.Size(20, 32), // scaled size
                        origin: new google.maps.Point(0, 0), // origin
                        anchor: new google.maps.Point(0, 0), // anchor
                      };
                      marker = new google.maps.Marker({
                        map: vm.map,
                        position: latlngset,
                        icon: icon,
                      });
                      var content =
                        `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Tag ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].tagid +
                        `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hive Status : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                        mapItems[item].data[i].colonystatus +
                        `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Hive/` +
                        mapItems[item].data[i]._id +
                        `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                      var infowindow = new google.maps.InfoWindow();

                      google.maps.event.addListener(
                        marker,
                        "click",
                        (function (marker, content, infowindow) {
                          return function () {
                            infowindow.setContent(content);
                            infowindow.open(vm.map, marker);
                          };
                        })(marker, content, infowindow)
                      );
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>