<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Apiaries </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pt-4>
      <v-flex xs3 xl2 text-left>
        <span class="itemValue">Apiary ID</span>
      </v-flex>
      <v-flex xs4 text-left>
        <span class="itemValue">Username</span>
      </v-flex>
      <v-flex xs5 xl4 text-left>
        <span class="itemValue">Location</span>
      </v-flex>
    </v-layout>
    <template v-for="(item, i) in storage">
      <router-link :to="'/Apiary/' + item._id" :key="i">
        <v-layout wrap justify-start>
          <v-flex xs3 xl2 text-left>
            <span class="itemKey">{{ item.apiaryid }}</span>
          </v-flex>
          <v-flex xs4 text-left>
            <span class="itemKey">{{ item.user.username }}</span>
          </v-flex>
          <v-flex xs5 xl6 text-left>
            <span class="itemKey">
              {{ item.locationname.slice(0, 25) }}
              <span v-if="item.locationname.length > 25">...</span>
              <span v-if="!item.locationname">-</span>
            </span>
          </v-flex>
        </v-layout>
      </router-link>
    </template>
    <v-layout wrap justify-center>
      <v-flex xs12 pr-4 text-right>
        <router-link to="/Admin/Apiary">
          <span
            style="font-family: poppinsregular; font-size: 13px; color: #0073cf"
          >
            View More
          </span>
          <span class="itemArrow" style="color: #0073cf">>></span>
        </router-link>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
};
</script>