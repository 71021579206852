<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex>
        <PageLoader v-bind:storage="appLoading" v-bind:contain="true" />
        <apexchart
          width="380"
          type="pie"
          :options="chartOptions"
          :series="chatData"
        ></apexchart>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    chatData() {
      return this.storage.series;
    },
    chartOptions: function () {
      const that = this;
      return {
        chart: {
          id: "HoneyTypes",
          toolbar: {
            show: false,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            size: 50,
          },
        },
        labels: that.storage.label,
      };
    },
  },
  methods: {
    getData() {},
  },
};
</script>